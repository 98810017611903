<template>
  <div class="user-cont">

    <div class="card-cont">
      <div class="card-item">
        <div class="card-icon">
          头像
        </div>
        <div class="card-text" style="padding: 6px 0;">
          <UpFileHeader :img="$init.pass.headimg" @updata="fileChange"></UpFileHeader>
        </div>
      </div>
      <div class="card-item">
        <div class="card-icon">
          昵称
        </div>
        <div class="card-text">
          <input v-model="form.nickname" type="text" class="input-text" placeholder="请输入">
        </div>
      </div>
    </div>
    
    <div class="btns">
      <cube-button class="btn btn1" @click="logoOut">退出登录</cube-button>
      <cube-button class="btn" @click="saveData">修改信息</cube-button>
    </div>
  </div>
</template>

<script>
import {Right} from '@icon-park/vue'
import UpFileHeader from '@/components/UpFileHeader.vue'
export default {
  components: {
    Right,
    UpFileHeader
  },
  name: 'Home',
  data () {
    return {
      form: {
        headimgResName: '',
        nickname: this.$init.pass.nickname,
      }
    }
  },
  mounted () {
    
  },
  methods: {
    /**
     * 退出登陆
     */
    logoOut () {
      this.$utils.setCache('logout', 'true');
      this.$router.replace('/login')
    },
    /**
     * 返回
     */
    goBack () {
      this.$router.go(-1)
    },
    fileChange (val) {
      this.form.headimgResName = val;
    },
    saveData () {
      const vm = this;
      var postData = {...this.form}
      if(! this.form.nickname){
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '请输入昵称',
          icon: ''
        }).show()
        return
      }
      this.$http
        .post("/user/user/modifyUserBaseInfo", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.$init.pass.nickname = vm.form.nickname;
            if(vm.form.headimgResName){
              vm.$init.pass.headimg = vm.$init.apis.default + '/access/file/' + vm.form.headimgResName;
            }
            vm.$utils.setCache('pass', vm.$init.pass);
            vm.goBack()
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
  },
  computed: {
    
  },  
}
</script>

<style lang="scss" scoped>
.user-cont{
  min-height: 100.1vh;
  background-color: #eee;
  // background-image: url(../assets/image/bg1.png);
  background-size: 100% 340px;
  background-position: 0 0;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 60px;
}
.card-cont{
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 25px 40px;
  border-radius: 8px;
  margin-bottom: 40px;
  background-color: #fff;
  .card-item{
    min-height: 100px;
    display: flex;
    border-top: 1px solid #f3f3f3;
    justify-content: center;
    align-items: center; 
    &:first-child{
      border: none;
    }
  }
  .card-icon{
    width: 80px;
    font-size: 28px;
    img{
      display: block;
      width: 42px;
    }
  }
  .card-text{
    font-size: 32px;
    flex: 10;
    text-align: right;
    .input-text{
      display: block;
      width: 100%;
      border: none;
      outline: none;
      text-align: right;
    }
  }
  .card-more{
    font-size: 40px;
  }
}
.btns{
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0 63px 0;
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  .btn{
    height: 80px;
    padding: 0;
    background: #1b945e;
    border-radius: 50px;
    margin: 0 auto;
    width: 330px;
  }
  .btn1{
    height: 80px;
    width: 260px;
    padding: 0;
    background-color: #1b945ebd;
    border-radius: 50px;
    margin: 0 auto;
    margin-right: 30px;
  }
}
</style>